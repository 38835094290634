import React, { useContext, useEffect } from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'


import SEO from "components/seo"
import Layout from "components/layout"
import { Other } from "components/pages/others/other"

const TermsConditionsId = ({ path, pageContext, location }) => {
	const data = useStaticQuery(GET_TERMS_ID)
	const lang = pageContext.langKey
	const pageData = lang === "en"
		? data?.wordPress?.page
		: data?.wordPress?.page?.translation
		|| data?.wordPress?.page

	const { seo } = lang === "en"
		? data.wordPress.page
		: data.wordPress.page.translation
		|| data.wordPress.page

	const SEODefaultImg = data.wordPress.featuredImage?.node?.sourceUrl

	return (
		<Layout path={path} lang={lang}>
			<SEO
				title={seo.title}
				desc={seo.metaDesc}
				fbImg={SEODefaultImg}
				fbTitle={seo.opengraphTitle}
				fbDesc={seo.opengraphDescription}
				url={location.href}
				twitterTitle={seo.twitterTitle}
				twitterDesc={seo.twitterDescription}
				twitterImg={SEODefaultImg}
			/>
			<Other data={pageData} />
		</Layout>
	)
}

export default TermsConditionsId;

const GET_TERMS_ID = graphql`
query GET_TERMS_ID {
	wordPress {
		page(id: "terms-conditions", idType: URI) {
			title
			seo {
				title
				metaDesc
				twitterTitle
				twitterDescription
				opengraphTitle
				opengraphDescription
			}
			featuredImage {
				node {
					sourceUrl
				}
			}
			blocks {
				originalContent
			}
			translation(language: ID) {
				title
				seo {
					title
					metaDesc
					twitterTitle
					twitterDescription
					opengraphTitle
					opengraphDescription
				}
				featuredImage {
					node {
						sourceUrl
					}
				}
				blocks {
					originalContent
				}
			}
		}
	}
}
`